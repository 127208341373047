import { useContext } from "react";

import { Box, Typography, Button } from "@mui/material";

import { Trans } from "../Translations";
import { MaterialsData } from "./DataTypes";

export default function MaterialChooser(props: {
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  setCurMaterial: (id: number) => void;
  curColors: { [key: number]: number };
  setCurColors: (newValue: { [key: number]: number }) => void;
  changeColorTo: (newColorId: number, newColorExternalId: string) => void;
  displayTitle: boolean;
}) {
  const { _ } = useContext(Trans);
  const curColor = props.curColors[props.curMaterial] ?? -1;
  return (
    <Box>
      {props.displayTitle && (
        <Typography
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.3em",
            fontWeight: 600,
            color: "#000",
            marginTop: "1em",
            marginBottom: "0.5em",
          }}
        >
          {_("Izberi material")}
        </Typography>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridTemplateRows: "1fr",
          gridColumnGap: "10px",
          gridRowGap: "10px",
        }}
      >
        {props.materialsData?.data.map((material) => {
          const isSelected = material.id === props.curMaterial;
          return (
            <Button
              key={material.id}
              variant="outlined"
              sx={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "0.9em",
                fontWeight: 300,
                textTransform: "none",
                border: "2px solid #d9d9d9",
                borderRadius: "3px",
                backgroundColor: isSelected ? "#000" : "#fff",
                borderColor: isSelected ? "#000" : "#d9d9d9",
                color: "#808080",
                ":hover": {
                  border: "2px solid #808080",
                  backgroundColor: "#fff",
                },
                "&.Mui-disabled": {
                  color: "#fff",
                },
              }}
              disabled={isSelected}
              onClick={() => {
                props.setCurMaterial(material.id);
                const color = material.colors.find(
                  (it) => it.id === props.curColors[material.id]
                );
                if (color === undefined) {
                  console.error();
                  return;
                }
                props.changeColorTo(color.id, color.external_id);
              }}
            >
              {material.name}
            </Button>
          );
        })}
      </Box>

      {props.displayTitle && (
        <Typography
          sx={{
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1.3em",
            fontWeight: 600,
            color: "#000",
            marginTop: "1em",
            marginBottom: "0.5em",
          }}
        >
          {_("Izberi barvo")}
        </Typography>
      )}
      <Box
        sx={{
          marginTop: props.displayTitle ? "0" : "15px",
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          gridTemplateRows: "1fr",
          gridColumnGap: "10px",
          gridRowGap: "10px",
        }}
      >
        {props.materialsData?.data
          .find((it) => it.id === props.curMaterial)
          ?.colors.map((color) => {
            const isSelected = color.id === curColor;
            const borderElement = {
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              borderRadius: "3px",
              content: '""',
            };
            return (
              <Button
                key={color.id}
                variant="outlined"
                disabled={isSelected}
                sx={{
                  minWidth: "inherit",
                  padding: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                  "&.Mui-disabled": {
                    border: "none",
                  },
                  ...(isSelected
                    ? {
                        "::after": {
                          ...borderElement,
                          border: "solid 3px #000",
                        },
                        "::before": {
                          ...borderElement,
                          border: "solid 6px #fff",
                        },
                      }
                    : {
                        ":hover": {
                          border: "none",
                          opacity: 0.7,
                        },
                      }),
                }}
                onClick={() => {
                  props.setCurColors({
                    ...props.curColors,
                    [props.curMaterial]: color.id,
                  });
                  props.changeColorTo(color.id, color.external_id);
                }}
              >
                <Box
                  component="img"
                  sx={{
                    borderRadius: "3px",
                    width: "100%",
                    // height: "100%",
                    // width: "calc(100% - 11px)",
                    // height: "calc(100% - 11px)",
                  }}
                  src={`https://api.envisionexpo.com/assets/${color.icon}`}
                />
              </Button>
            );
          })}
      </Box>
    </Box>
  );
}

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { apps, StreamApp } from "./Apps";

export default function AppList() {
  const navigate = useNavigate();
  console.log("Rendering app list...");
  return (
    <Box
      sx={{
        width: "50%",
        height: "100%",
        bgcolor: "background.paper",
      }}
    >
      <List
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <ListSubheader component="div" id="nested-list-subheader">
          PLEASE CHOOSE AN APP
        </ListSubheader>
        {apps.map(function (app: StreamApp) {
          return (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(`/${app.link}`);
                }}
              >
                <ListItemText primary={app.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

import { useState, useEffect } from "react";

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import {
  UserPreset,
  SofaPreset,
  CurrentElement,
  TypesData,
  MaterialsData,
  CurrentElements,
  ElementsData,
  UserTypes,
  CurPreset,
} from "./DataTypes";
import PresetChooser from "./PresetChooser";
import Sidebar from "./Sidebar";
import ElementDetails from "./ElementDetails";
import { client } from "../RpcClient";
import ActionBar from "./ActionBar";

const localStorageKey = "ree_feydom_user_sofa_types";
function getUserTypesFromLocalStorage(): UserTypes {
  return JSON.parse(window.localStorage.getItem(localStorageKey) ?? "{}");
}

export default function SofaIndex(props: {}) {
  const [elementsData, setElementsData] = useState<ElementsData>();
  const [typesData, setTypesData] = useState<TypesData>();
  const [curType, setCurType] = useState<number>(-1);
  const [curPresets, setCurPresets] = useState<{
    [key: number]: CurPreset;
  }>({});
  const [materialsData, setMaterialsData] = useState<MaterialsData>();
  const [curMaterial, setCurMaterial] = useState<number>(-1);
  const [curColors, setCurColors] = useState<{ [key: number]: number }>({});
  const [curElements, setCurElements] = useState<CurrentElements>({
    elements: [],
  });
  const [userTypes, setUserTypes] = useState<UserTypes>(
    getUserTypesFromLocalStorage()
  );
  const [pendingUserPresetDelete, setPendingUserPresetDelete] =
    useState<UserPreset>();
  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(userTypes));
  }, [userTypes]);
  useEffect(() => {
    window.addEventListener("storage", (event) => {
      setUserTypes(getUserTypesFromLocalStorage());
    });
  }, []);
  useEffect(() => {
    const getMaterials = async () => {
      const response: MaterialsData = await fetch(
        "https://api.envisionexpo.com/items/sofa_materials?fields=*,colors.*"
      ).then((response) => response.json());
      setMaterialsData(response);
      const newCurColors: { [key: number]: number } = {};
      const materials = response as MaterialsData;
      for (const material of materials.data) {
        newCurColors[material.id] = material.colors[0].id;
      }
      setCurColors(newCurColors);
      setCurMaterial(materials.data[0].id);
    };
    getMaterials();
    const getPresets = async () => {
      const response: TypesData = await fetch(
        "https://api.envisionexpo.com/items/sofa_types?fields=*,presets.*&sort=sort"
      ).then((response) => response.json());
      for (const type of response.data) {
        type.presets.sort((it, it2) => it.sort - it2.sort);
      }
      setTypesData(response);
    };
    getPresets();
    const getElements = async () => {
      const response: ElementsData = await fetch(
        `https://api.envisionexpo.com/items/sofa_elements?fields=*,states.*`
      ).then((response) => response.json());
      console.log(response);
      setElementsData(response);
    };
    getElements();
  }, []);
  useEffect(() => {
    (window as any).sofa_elements_data_update = function (
      action: CurrentElements
    ) {
      console.log("DATA UPDATE");
      const extWindow = window as any;
      if (extWindow.reeWasRestored === true) {
        delete extWindow.reeWasRestored;
        console.log("IGNORING THIS CALL");
      } else {
        const curPreset = curPresets[curType];
        if (curPreset === undefined) {
          console.error();
          return;
        }
        if (curPreset.isUserPreset) {
          const newUserTypes = { ...userTypes };
          const newUserType = newUserTypes[curType];
          if (newUserType === undefined) {
            console.error();
          }
          const currentUserPreset = newUserType.presets.find(
            (it) => it.id === curPreset.id
          );
          if (currentUserPreset === undefined) {
            console.error();
            return;
          }
          currentUserPreset.elements = action.elements;
          setUserTypes(newUserTypes);
        } else {
          if (extWindow.reeWasChangeColorAll === true) {
            delete extWindow.reeWasChangeColorAll;
            console.log("IGNORING THIS CALL 2");
          } else {
            const newUserTypes = { ...userTypes };
            let newUserType = newUserTypes[curType];
            if (newUserType === undefined) {
              newUserType = { maxCount: 0, presets: [] };
              newUserTypes[curType] = newUserType;
            }
            ++newUserType.maxCount;
            const currentTypeObject = typesData?.data.find(
              (it) => it.id === curType
            );
            if (currentTypeObject === undefined) {
              console.error();
              return;
            }
            const newId = Date.now();
            newUserType.presets.push({
              id: newId,
              name: `Moj ${currentTypeObject.name} ${newUserTypes[curType].maxCount}`,
              elements: action.elements,
            });
            setUserTypes(newUserTypes);
            const newCurPresets = { ...curPresets };
            newCurPresets[curType] = { isUserPreset: true, id: newId };
            setCurPresets(newCurPresets);
          }
        }
      }
      setCurElements(action);
    };
  }, [curType, curPresets, curElements, userTypes, typesData]);
  const curPreset = curPresets[curType];
  const onPresetClick = async (preset: SofaPreset, curTypeReal: number) => {
    setCurPresets({
      ...curPresets,
      [curTypeReal]: {
        isUserPreset: false,
        id: preset.id,
      },
    });
    const elementPositions = JSON.parse(
      preset.element_positions
    ) as CurrentElement[];
    const curColor = materialsData?.data
      ?.find((it) => it.id === curMaterial)
      ?.colors.find((it) => it.id === curColors[curMaterial]);
    if (curColor === undefined) {
      throw Error();
    }
    const extWindow = window as any;
    extWindow.reeWasRestored = true;
    await client.request("SofaRestore", {
      elements: elementPositions.map((element) => {
        const curElement = elementsData?.data.find((it) => it.id === element.i);
        if (curElement === undefined) {
          throw Error();
        }
        const curState = curElement.states.find((it) => it.id === element.s);
        return {
          ...element,
          external_id: curElement.external_id,
          color_external_id: curColor.external_id,
          c: curColor.id,
          s: element.s ?? -1,
          state_external_id: curState?.external_id ?? "",
        };
      }),
    });
  };
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
      }}
    >
      <Dialog
        open={pendingUserPresetDelete !== undefined}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Izbrisite vaso postavitev?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Ali res zelite izbrisati vaso postavitev "' +
              (pendingUserPresetDelete?.name ?? "") +
              '"'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPendingUserPresetDelete(undefined)}>
            Preklici
          </Button>
          <Button
            onClick={() => {
              const userPreset = pendingUserPresetDelete;
              if (userPreset === undefined) {
                console.error();
                return;
              }
              const newUserTypes = { ...userTypes };
              const newUserType = newUserTypes[curType];
              if (newUserType === undefined) {
                console.error();
              }
              const presetIndex = newUserType.presets.findIndex(
                (it) => it.id === userPreset.id
              );
              if (presetIndex === -1) {
                console.error();
                return;
              }
              newUserType.presets.splice(presetIndex, 1);
              setUserTypes(newUserTypes);
              const presets = typesData?.data.find(
                (it) => it.id === curType
              )?.presets;
              if (presets === undefined || presets.length < 1) {
                console.error();
                return;
              }
              onPresetClick(presets[0], curType);
              setPendingUserPresetDelete(undefined);
            }}
          >
            Izbrisi
          </Button>
        </DialogActions>
      </Dialog>
      <PresetChooser
        typesData={typesData}
        userTypes={userTypes}
        curType={curType}
        curPreset={curPreset}
        onTypeClick={(type) => {
          setCurType(type.id);
          const currentTypeObject = typesData?.data.find(
            (it) => it.id === type.id
          );
          if (currentTypeObject === undefined) {
            console.error();
            return;
          }
          const curPreset = curPresets[type.id];
          if (curPreset === undefined) {
            onPresetClick(currentTypeObject.presets[0], type.id);
          } else {
            if (curPreset.isUserPreset) {
              console.log("TODO TODO TODO");
              // TODO add logic here
            } else {
              const currentPreset = currentTypeObject.presets.find(
                (it) => it.id === curPreset.id
              );
              if (currentPreset === undefined) {
                console.error();
                return;
              }
              onPresetClick(currentPreset, type.id);
            }
          }
        }}
        onPresetClick={(preset) => onPresetClick(preset, curType)}
        onUserPresetClick={(userPreset) => {
          (window as any).reeWasRestored = true;
          const event = {
            elements: userPreset.elements.map((element) => {
              const curElement = elementsData?.data.find(
                (it) => it.id === element.i
              );
              if (curElement === undefined) {
                throw Error();
              }
              let colorExternalId: string | undefined = undefined;
              for (const material of materialsData?.data ?? []) {
                for (const color of material.colors) {
                  if (color.id === element.c) {
                    colorExternalId = color.external_id;
                    break;
                  }
                }
              }
              if (colorExternalId === undefined) {
                throw Error();
              }
              const curState = curElement.states.find(
                (it) => it.id === element.s
              );
              return {
                ...element,
                external_id: curElement.external_id,
                color_external_id: colorExternalId,
                s: element.s ?? -1,
                state_external_id: curState?.external_id ?? "",
              };
            }),
          };
          client.request("SofaRestore", event);
          setCurPresets({
            ...curPresets,
            [curType]: {
              isUserPreset: true,
              id: userPreset.id,
            },
          });
        }}
        onUserPresetDelete={(userPreset) =>
          setPendingUserPresetDelete(userPreset)
        }
        onTypesButtonClick={() => setCurType(-1)}
        showCurrentElements={() => {
          alert(JSON.stringify(curElements.elements));
        }}
      />
      <Sidebar
        materialsData={materialsData}
        curMaterial={curMaterial}
        setCurMaterial={setCurMaterial}
        curColors={curColors}
        setCurColors={setCurColors}
        typesData={typesData}
        curType={curType}
        curPreset={curPreset}
        curElements={curElements}
        elementsData={elementsData}
        userTypes={userTypes}
        setUserTypes={setUserTypes}
      />
      <ElementDetails
        elementsData={elementsData}
        materialsData={materialsData}
        curMaterial={curMaterial}
        curColors={curColors}
      />
      <ActionBar />
    </Box>
  );
}

import { useContext, useState, useEffect, DragEvent } from "react";

import { Box, Typography, IconButton, Tab, Tabs } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { Trans } from "../Translations";
import {
  SofaElementCategoriesData,
  SofaElementCategory,
  SofaType,
  SofaColor,
  ElementsData,
  SofaElement,
} from "./DataTypes";
import { client } from "../RpcClient";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: "20px 0" }}>{children}</Box>}
    </div>
  );
}

function allyProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ElementChooserList(props: {
  elements: SofaElement[];
  curColor: SofaColor | undefined;
}) {
  const [curScroll, setCurScroll] = useState<number>(0);
  const itemsPerPage = 4;
  return (
    <Box
      sx={{
        // marginLeft: "25px",
        // marginRight: "25px",
        position: "relative",
        display: "grid",
        gridTemplateColumns: `repeat(${itemsPerPage}, 1fr)`,
        gridTemplateRows: "1fr",
        gridColumnGap: "25px",
        gridRowGap: "25px",
      }}
    >
      {curScroll > 0 && (
        <IconButton
          sx={{
            padding: 0,
            position: "absolute",
            top: "50%",
            left: "-15px",
            transform: "translate(-50%, -50%)",
          }}
          onClick={() => {
            setCurScroll(curScroll - 1);
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      )}
      {props.elements
        .slice(curScroll, curScroll + itemsPerPage)
        .map((element) => {
          return (
            <Box key={element.id} sx={{}}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: 0,
                  paddingTop: "100%",
                }}
              >
                <Box
                  component="img"
                  src={`/ree_renders_webp/feydom/${element.external_id}-${props.curColor?.external_id}.webp`}
                  draggable="true"
                  onDragStart={(ev: DragEvent<HTMLImageElement>) => {
                    console.log(`DRAG STARTED :): ${element.external_id}`);
                    ev.dataTransfer.setData(
                      "application/ree-element-json",
                      JSON.stringify(element)
                    );
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: "0.9em",
                  fontWeight: 300,
                  color: "#808080",
                  textAlign: "center",
                }}
              >
                {element.name}
              </Typography>
            </Box>
          );
        })}
      {curScroll + itemsPerPage < props.elements.length && (
        <IconButton
          sx={{
            padding: 0,
            position: "absolute",
            top: "50%",
            right: "-12.5px",
            transform: "translate(50%, -50%)",
          }}
          onClick={() => {
            setCurScroll(curScroll + 1);
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default function ElementChooser(props: {
  curType: SofaType | undefined;
  curColor: SofaColor | undefined;
  elementsData: ElementsData | undefined;
}) {
  const { _ } = useContext(Trans);
  const [tabsValue, setTabsValue] = useState(0);
  const [elementCategories, setElementCategories] = useState<{
    data: SofaElementCategory[];
  }>({ data: [] });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };
  useEffect(() => {
    const getApiData = async () => {
      const response: SofaElementCategoriesData = await fetch(
        `https://api.envisionexpo.com/items/sofa_element_categories?fields=*,translations.name`
      ).then((response) => response.json());
      setElementCategories(response);
    };
    getApiData();
  }, []);
  useEffect(() => {
    const curType = props.curType;
    if (curType !== undefined) {
      const player = document.getElementById("player");
      if (player === null) {
        console.error();
        return;
      }
      player.ondragenter = function (this, ev): any {
        ev.preventDefault();
        const data = ev.dataTransfer?.getData("application/ree-element-json");
        console.log(`DRAG ENTER: ${data}`);
      };
      player.ondragleave = function (this, ev): any {
        ev.preventDefault();
        const data = ev.dataTransfer?.getData("application/ree-element-json");
        console.log(`DRAG LEAVE: ${data}`);
      };
      player.ondragover = function (this, ev): any {
        ev.preventDefault();
        // const data = ev.dataTransfer?.getData("application/ree-element-json");
        console.log(`DRAG OVER: ${ev.clientX} x ${ev.clientY}`);
      };
      player.ondrop = function (this, ev): any {
        ev.preventDefault();
        const data = ev.dataTransfer?.getData("application/ree-element-json");
        if (data == null) {
          console.error();
          return;
        }
        const element = JSON.parse(data);
        console.log(`DROPPED: ${data}`);
        const bounds = player.getBoundingClientRect();
        console.log(bounds);
        const curColor = props.curColor;
        if (curColor === undefined) {
          console.error();
          return;
        }
        const event = {
          element_id: element.id,
          external_id: element.external_id,
          color_id: curColor.id,
          color_external_id: curColor.external_id,
          mouse_position_x: ev.pageX - bounds.x,
          mouse_position_y: ev.pageY - bounds.y,
        };
        console.log(
          `XY: ${event.mouse_position_x} x ${event.mouse_position_y}`
        );
        client.request("SofaOnDrop", event);
        /*
        const newUserPresets = userTypes[curType] ?? {
          maxCount: 1,
          types: [],
        };
        newUserPresets.types.push({
          id: Date.now(),
          name: curType.name,
          elements: [],
        });
        setUserTypes({ ...userTypes, [curType.id]: newUserPresets });
        */
      };
    }
  }, [props]);
  const groupedByCategories: Array<{
    category: SofaElementCategory;
    elements: SofaElement[];
  }> = [];
  for (const element of props.elementsData?.data ?? []) {
    if (element.sofa_type === props.curType?.id) {
      const categoryObj = groupedByCategories.find(
        (it) => it.category.id === element.category
      );
      if (categoryObj === undefined) {
        const category = elementCategories.data.find(
          (it) => it.id === element.category
        );
        if (category === undefined) {
          throw Error();
        }
        groupedByCategories.push({ category: category, elements: [element] });
      } else {
        categoryObj.elements.push(element);
      }
    }
  }
  groupedByCategories.sort((it, it2) => it.category.sort - it2.category.sort);
  console.log(props.elementsData);
  console.log(groupedByCategories);
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: '"Montserrat", sans-serif',
          fontSize: "1.3em",
          fontWeight: 600,
          color: "#000",
          marginTop: "1em",
          marginBottom: "0.5em",
        }}
      >
        {_("Izberi elemente")}
      </Typography>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabsValue} onChange={handleChange}>
            {groupedByCategories.map((catData, index) => {
              return (
                <Tab
                  key={catData.category.id}
                  label={catData.category.translations[0].name}
                  {...allyProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        {groupedByCategories.map((catData, index) => {
          return (
            <TabPanel key={catData.category.id} value={tabsValue} index={index}>
              <ElementChooserList
                elements={catData.elements}
                curColor={props.curColor}
              />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
}

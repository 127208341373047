import { Box, Typography, TextField } from "@mui/material";

import {
  ElementsData,
  TypesData,
  MaterialsData,
  CurrentElements,
  CurPreset,
  UserTypes,
} from "./DataTypes";
import { client } from "../RpcClient";
import MaterialChooser from "./MaterialChooser";
import ElementChooser from "./ElementChooser";
import Price from "./Price";

function changeColorTo(newColorId: number, newColorExternalId: string) {
  (window as any).reeWasChangeColorAll = true;
  client.request("SofaChangeColorAll", {
    color_id: newColorId,
    color_external_id: newColorExternalId,
  });
}

export default function Sidebar(props: {
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  setCurMaterial: (id: number) => void;
  curColors: { [key: number]: number };
  setCurColors: (newValue: { [key: number]: number }) => void;
  typesData: TypesData | undefined;
  curType: number;
  curPreset: CurPreset | undefined;
  curElements: CurrentElements;
  elementsData: ElementsData | undefined;
  userTypes: UserTypes;
  setUserTypes: (newUserTypes: UserTypes) => void;
}) {
  const curTypeObj = props.typesData?.data.find(
    (it) => it.id === props.curType
  );
  const curColor = props.materialsData?.data
    ?.find((it) => it.id === props.curMaterial)
    ?.colors.find((it) => it.id === props.curColors[props.curMaterial]);
  return (
    <Box
      sx={{
        pointerEvents: "initial",
        position: "absolute",
        top: "160px",
        right: 0,
        width: "500px",
        bottom: 0,
        overflowY: "auto",
        marginBottom: "95px",
      }}
    >
      <Box sx={{ marginLeft: "25px", marginRight: "25px" }}>
        {(() => {
          const curPreset = props.curPreset;
          if (curPreset !== undefined) {
            if (curPreset.isUserPreset) {
              const curPresetObj = props.userTypes[props.curType]?.presets.find(
                (it) => it.id === curPreset.id
              );
              if (curPresetObj) {
                return (
                  <TextField
                    label="Ime tvoje sedezne"
                    variant="outlined"
                    value={curPresetObj.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newName = event.target.value;
                      const newUserTypes = { ...props.userTypes };
                      for (const preset of newUserTypes[props.curType]
                        .presets) {
                        if (preset.id === curPreset.id) {
                          preset.name = newName;
                          break;
                        }
                      }
                      props.setUserTypes(newUserTypes);
                    }}
                    sx={{
                      marginTop: "5px",
                      color: "#000",
                      "& .MuiOutlinedInput-input": {
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: "1.5em",
                        fontWeight: 600,
                        textAlign: "center",
                      },
                    }}
                  />
                );
              }
            } else {
              const curPresetObj = curTypeObj?.presets.find(
                (it) => it.id === curPreset.id
              );
              if (curPresetObj) {
                return (
                  <Typography
                    sx={{
                      fontFamily: '"Montserrat", sans-serif',
                      fontSize: "2.5em",
                      fontWeight: 600,
                      marginBottom: "0.3em",
                      color: "#000",
                    }}
                  >
                    {curPresetObj.name}
                  </Typography>
                );
              }
            }
          }
          return false;
        })()}
        <MaterialChooser
          materialsData={props.materialsData}
          curMaterial={props.curMaterial}
          setCurMaterial={props.setCurMaterial}
          curColors={props.curColors}
          setCurColors={props.setCurColors}
          changeColorTo={changeColorTo}
          displayTitle={true}
        />
        <ElementChooser
          curType={curTypeObj}
          curColor={curColor}
          elementsData={props.elementsData}
        />
      </Box>
      <Price curElements={props.curElements} />
    </Box>
  );
}

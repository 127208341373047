import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { apps } from "./Apps";
import SignUp from "./SignUp";
import Player from "./Player";

export default function App() {
  const params = useParams();
  const app = apps.find((it) => params.appId === it.link);
  if (app === undefined) {
    throw Error();
  }
  const [userId, setUserId] = useState<number | null>(null);
  const curUserId = userId;
  if (curUserId === null && app.loginEnabled === true) {
    return <SignUp setUserId={setUserId} />;
  } else {
    return <Player userId={curUserId} />;
  }
}

import { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import {
  Box,
  Popover,
  ClickAwayListener,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";

import { ElementsData, MaterialsData } from "./DataTypes";
import { client } from "../RpcClient";

import MaterialChooser from "./MaterialChooser";
import ElementDetailsAdmin from "./ElementDetailsAdmin";

export default function ElementDetails(props: {
  elementsData: ElementsData | undefined;
  materialsData: MaterialsData | undefined;
  curMaterial: number;
  curColors: { [key: number]: number };
}) {
  const [popoverData, setPopoverData] = useState<{
    open: boolean;
    position: {
      top: number;
      left: number;
    };
    color: string | undefined;
    pointer: number | undefined;
    element_id: number;
  }>({
    open: false,
    position: { top: 0, left: 0 },
    color: undefined,
    pointer: undefined,
    element_id: -1,
  });
  const [params] = useSearchParams();
  const [curPopupMaterial, setCurPopupMaterial] = useState<number>(-1);
  const [curPopupColors, setCurPopupColors] = useState<{
    [key: number]: number;
  }>({});
  useEffect(() => {
    (window as any).sofa_element_popup = async function (action: any) {
      console.log("EL POPUP", action);
      const player = document.getElementById("player");
      if (player === null) {
        console.error();
        return;
      }
      const bounds = player.getBoundingClientRect();
      console.log(action);
      const curColorExternalId = action.color as string;
      const newColors: {
        [key: number]: number;
      } = {};
      for (const material of props.materialsData?.data ?? []) {
        newColors[material.id] = props.curColors[material.id];
        for (const color of material.colors) {
          if (color.external_id === curColorExternalId) {
            setCurPopupMaterial(material.id);
            newColors[material.id] = color.id;
          }
        }
      }
      setCurPopupColors(newColors);
      setPopoverData({
        open: true,
        position: {
          top: bounds.y + action.mouse_y - 20,
          left: bounds.x + action.mouse_x - 20,
        },
        color: action.color,
        pointer: action.pointer,
        element_id: action.element_id,
      });
    };
  }, [props, params]);
  console.log("POPOVER DATA:", popoverData);
  const pointer = popoverData.pointer;
  const isAdmin = params.get("adminKey") !== null;
  const elementData = props.elementsData?.data.find(
    (it) => it.id === popoverData.element_id
  );
  console.log("ELEMENT DATA:", elementData);
  return (
    <Popover
      open={popoverData.open}
      anchorReference="anchorPosition"
      anchorPosition={
        isAdmin ? { left: window.innerWidth, top: 0 } : popoverData.position
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          if (!isAdmin) {
            setPopoverData({ ...popoverData, open: false });
          }
        }}
      >
        <Box
          sx={{
            width: isAdmin ? "500px" : "300px",
            // maxHeight: "50vh",
            maxHeight: "80vh",
            margin: "20px",
          }}
        >
          {isAdmin && popoverData.open && pointer !== undefined && (
            <ElementDetailsAdmin
              pointer={pointer}
              popupClose={() => setPopoverData({ ...popoverData, open: false })}
            />
          )}
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            {elementData !== undefined && (
              <Typography
                sx={{
                  flexGrow: 1,
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: "1.3em",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                {elementData.name}
              </Typography>
            )}
            <IconButton
              color="primary"
              onClick={() => {
                const pointer = popoverData.pointer;
                if (pointer === undefined) {
                  console.error();
                  return;
                }
                client.request("SofaRotate", {
                  pointer: pointer,
                });
              }}
            >
              <Rotate90DegreesCwIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                const pointer = popoverData.pointer;
                if (pointer === undefined) {
                  console.error();
                  return;
                }
                client.request("SofaDestroy", { pointer: pointer });
                setPopoverData({ ...popoverData, open: false });
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
          {pointer !== undefined &&
            elementData !== undefined &&
            elementData.states.length > 0 && (
              <Box>
                {elementData.states.map((state) => (
                  <Button
                    key={state.external_id}
                    onClick={() => {
                      client.request("SofaSwitchToState", {
                        pointer: pointer,
                        state_external_id: state.external_id,
                        state_id: state.id,
                      });
                    }}
                  >
                    {state.name}
                  </Button>
                ))}
              </Box>
            )}
          <MaterialChooser
            materialsData={props.materialsData}
            curMaterial={curPopupMaterial}
            setCurMaterial={setCurPopupMaterial}
            curColors={curPopupColors}
            setCurColors={setCurPopupColors}
            changeColorTo={(newColorId, newColorExternalId) => {
              const pointer = popoverData.pointer;
              if (pointer === undefined) {
                console.error();
                return;
              }
              client.request("SofaChangeColor", {
                pointer: pointer,
                color_id: newColorId,
                color_external_id: newColorExternalId,
              });
            }}
            displayTitle={false}
          />
        </Box>
      </ClickAwayListener>
    </Popover>
  );
}

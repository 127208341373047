import { useContext } from "react";

import { useSearchParams } from "react-router-dom";
import { Box, IconButton, Button, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import {
  UserPreset,
  CurPreset,
  UserTypes,
  TypesData,
  SofaPreset,
  SofaType,
} from "./DataTypes";
import { Trans } from "../Translations";

function PresetChoice(props: {
  selected: boolean;
  preset: SofaPreset;
  onClick: () => void;
}) {
  return (
    <Button
      sx={{
        padding: 0,
        lineHeight: "1.1em",
        opacity: props.selected ? 1.0 : 0.7,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
      }}
      disabled={props.selected}
      onClick={props.onClick}
    >
      {props.preset.icon && (
        <Box
          sx={{
            width: "80px",
            height: "80px",
            backgroundImage: `url('https://api.envisionexpo.com/assets/${props.preset.icon}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
          }}
        />
      )}
      <Box
        sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
      >
        <Typography
          component="span"
          sx={{
            textTransform: "none",
            font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "bold",
            fontSize: "0.9em",
            color: "#000",
            marginBottom: "0.3em",
          }}
        >
          {props.preset.name}
        </Typography>
        <Typography
          component="span"
          sx={{
            textTransform: "none",
            font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "normal",
            fontSize: "0.85em",
            color: "#333333",
          }}
        >
          {props.preset.width} x {props.preset.height}
        </Typography>
      </Box>
    </Button>
  );
}

function TypeChoice(props: { type: SofaType; onClick: () => void }) {
  return (
    <Button
      sx={{
        padding: 0,
        lineHeight: "1.1em",
        opacity: 0.7,
        ":hover": {
          opacity: 1.0,
          backgroundColor: "#fff",
        },
      }}
      onClick={props.onClick}
    >
      {props.type.icon && (
        <Box
          sx={{
            width: "80px",
            height: "80px",
            backgroundImage: `url('https://api.envisionexpo.com/assets/${props.type.icon}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: props.type.icon == null ? "0" : "10px",
        }}
      >
        <Typography
          component="span"
          sx={{
            textTransform: "none",
            font: 'normal 100% "Open Sans", sans-serif',
            fontWeight: "bold",
            fontSize: "0.9em",
            color: "#000",
            marginBottom: "0.3em",
          }}
        >
          {props.type.name}
        </Typography>
      </Box>
    </Button>
  );
}

function UserPresetChoice(props: {
  selected: boolean;
  name: string;
  onClick: () => void;
  onDelete: () => void;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      <Button
        sx={{
          height: "80px",
          padding: 0,
          paddingRight: props.selected ? "15px" : 0,
          lineHeight: "1.1em",
          opacity: props.selected ? 1.0 : 0.7,
          ":hover": {
            opacity: 1.0,
            backgroundColor: "#fff",
          },
        }}
        disabled={props.selected}
        onClick={props.onClick}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
          <Typography
            component="span"
            sx={{
              textTransform: "none",
              font: 'normal 100% "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "0.9em",
              color: "#000",
              marginBottom: "0.3em",
            }}
          >
            {props.name}
          </Typography>
        </Box>
      </Button>
      {props.selected && (
        <IconButton
          sx={{
            position: "absolute",
            top: "0",
            right: "-15px",
            border: "solid 1px",
            padding: "3px",
          }}
          onClick={props.onDelete}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default function PresetChooser(props: {
  typesData: TypesData | undefined;
  curType: number;
  curPreset: CurPreset | undefined;
  userTypes: UserTypes;
  onTypeClick: (type: SofaType) => void;
  onPresetClick: (preset: SofaPreset) => void;
  onUserPresetClick: (preset: UserPreset) => void;
  onUserPresetDelete: (preset: UserPreset) => void;
  onTypesButtonClick: () => void;
  showCurrentElements: () => void;
}) {
  const [params] = useSearchParams();
  const curPreset = props.curPreset;
  const { changeLocale } = useContext(Trans);
  const children = [];
  if (props.curType === -1) {
    children.push(
      ...(props.typesData?.data ?? []).map((it) => {
        return (
          <TypeChoice
            key={it.id}
            type={it}
            onClick={() => props.onTypeClick(it)}
          />
        );
      })
    );
  } else {
    children.push(
      <Button key={-1} variant="outlined" onClick={props.onTypesButtonClick}>
        Sedežni sestavi
      </Button>
    );
    children.push(
      ...(
        props.typesData?.data.find((it) => it.id === props.curType)?.presets ??
        []
      ).map((it) => {
        return (
          <PresetChoice
            key={it.id}
            selected={
              curPreset !== undefined &&
              !curPreset.isUserPreset &&
              it.id === curPreset.id
            }
            preset={it}
            onClick={() => props.onPresetClick(it)}
          />
        );
      })
    );
    children.push(
      ...(props.userTypes[props.curType]?.presets ?? []).map((it) => {
        return (
          <UserPresetChoice
            key={it.id}
            selected={
              curPreset !== undefined &&
              curPreset.isUserPreset &&
              it.id === curPreset.id
            }
            name={it.name}
            onClick={() => props.onUserPresetClick(it)}
            onDelete={() => props.onUserPresetDelete(it)}
          />
        );
      })
    );
  }
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        height: "160px",
        pointerEvents: "initial",
      }}
    >
      <Box
        sx={{
          height: "60px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          sx={{ height: "40px", marginLeft: "25px" }}
          src="/imgs/feydom_logo.svg"
        />
        {params.get("adminKey") !== null && (
          <Button
            sx={{ position: "absolute", right: "420px" }}
            onClick={props.showCurrentElements}
            variant="outlined"
          >
            Show current elements
          </Button>
        )}
        <Button
          sx={{ position: "absolute", right: "350px" }}
          onClick={() => changeLocale("fr_FR")}
          variant="outlined"
        >
          Fr
        </Button>
        <Button
          sx={{ position: "absolute", right: "280px" }}
          onClick={() => changeLocale("en_US")}
          variant="outlined"
        >
          En
        </Button>
        <Button
          sx={{ position: "absolute", right: "210px" }}
          onClick={() => changeLocale("sl_SI")}
          variant="outlined"
        >
          Sl
        </Button>
        <Button
          component="a"
          href="https://feydom2.idearna.digital/"
          sx={{ position: "absolute", right: "25px" }}
          variant="outlined"
        >
          Klasične sedežne
        </Button>
      </Box>
      <Box
        sx={{
          marginLeft: "25px",
          display: "flex",
          alignItems: "center",
          gap: "25px",
          height: "80px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

import { useState } from "react";

import { Fab, Box } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import AutoFixOff from "@mui/icons-material/AutoFixOff";

import { client } from "../RpcClient";

export default function ActionBar(props: {}) {
  const [autoSnap, setAutoSnap] = useState<boolean>(true);
  return (
    <Box
      sx={{
        position: "absolute",
        top: "160px",
        left: 0,
        overflow: "none",
        pointerEvents: "initial",
      }}
    >
      <Fab
        sx={{ margin: "15px" }}
        onClick={async () => {
          setAutoSnap(
            (await client.request("SofaSwitchAutoSnap", {})).is_auto_snap
          );
        }}
        aria-label="autosnap"
      >
        {autoSnap ? <AutoFixHighIcon /> : <AutoFixOff />}
      </Fab>
    </Box>
  );
}
